$icon-font-path: "~font-awesome/fonts/";
$fa-font-path: "~font-awesome/fonts";
$brand-primary: darken(#428bca, 20%);
@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@import '~startbootstrap-sb-admin/vendor/fontawesome-free/scss/fontawesome.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~startbootstrap-sb-admin/scss/sb-admin.scss';
@import "~font-awesome/css/font-awesome.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox';
